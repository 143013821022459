import { useEffect, useState } from 'react';
import { Button, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import SignatureCanvas from 'react-signature-canvas'
import 'react-toastify/dist/ReactToastify.css';
import '../App.css';


// home functional react component

const Waiver = ({ data }) => {
  const [minor, setMinor] = useState(false)
  const [bdaySelected, selectBday] = useState(false)
  const [sig, setSig] = useState('')
  const [gSig, setGSig] = useState('')
  const [showSig, setShowSig] = useState(true)
  const [showGSig, setShowGSig] = useState(true)
  const [uCheck, setUcheck] = useState(true)
  const [cCheck, setCCheck] = useState(true)
  const [gCheck, setGCheck] = useState(true)
  const [calculatedAge, setCalculatedAge] = useState(0)
  const [sDis, setSDis] = useState(true)
  const [nagging, setNagging] = useState(false)

  useEffect(() => {
    window.setSDis = setSDis
    const robots = document.querySelector('meta[name="robots"]');
    robots.content = "index";
    const dateArr = new Date().toLocaleString().split(/\D/).slice(0, 3)
    dateArr.unshift(dateArr.pop())
    const d = document.getElementById('date')
    d.value = dateArr.map(num => num.padStart(2, "0")).join("-")
  }, [])

  useEffect(() => {
    if (data['waiver-meta-title']) {
      document.title = data['waiver-meta-title'];
    }
    if (data['waiver-description']) {
      document.querySelector('meta[name="description"]').content = data['waiver-description'];
    }
  }, [data])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSDis(true)
    const form = e.target
    const data = new FormData(form)
    const dataObj = {}
    dataObj.age = calculatedAge
    data.forEach((value, key) => {
      dataObj[key] = value
    })
    dataObj['sig'] = sig.toDataURL()
    if (sig.isEmpty()) {
      setSDis(false)
      return toast.error('Please sign the waiver')
    }

    if (minor) {
      dataObj['gsig'] = gSig.toDataURL()
      if (gSig.isEmpty()) {
        setSDis(false)
        return toast.error('Please have a guardian sign the waiver')
      }
    }

    try {
      const req = await fetch('/waiver.php', {
        method: 'POST',
        contentType: 'application/json',
        body: JSON.stringify(dataObj)
      })
      const res = await req.json()
      if (res.success) {
        e.target.reset()
        return toast("Waiver submitted successfully!", { type: "success" })
      }
      setSDis(false)
      toast(`There was an error, ${res.error}`, { type: "error" })
    } catch (error) {
      setSDis(false)
      console.log(error)
      toast("Something went wrong, please check your form data or try again later", { type: "error" });
    }
  }


  return (
    <div className="App">
      <ToastContainer />
      <h1 style={{ color: "#a6a6a6", paddingTop: "2rem" }} data-cy='title'>
        {data['waiver-title']}
      </h1>
      <Row className='padding-x-xl'>
        <Col className='offset-md-2' sm='12' md='8' style={{ background: 'white', padding: '40px' }}>
          <div dangerouslySetInnerHTML={{__html: data['waiver-page-top']}} />
          {/* <Form inline action="https://mailthis.to/info@jacksaxes.co" method="POST"> */}
          <Form inline onSubmit={handleSubmit}>
            <div dangerouslySetInnerHTML={{ __html: data['waiver-main'] }} />
            <hr></hr>
            <Row>
              <Col sm='12'>
                {data['waiver-understand']}
              </Col>
              <Col sm='12'>
                <br></br>
                <label for='understand'>
                  <span>I agree. </span><Input id='understand' onClick={() => setUcheck(!uCheck)} name='understand' type='checkbox' checked={uCheck} disabled={minor}></Input>
                {!minor && ' *'}
                  <br />
                  <span>{data['waiver-opt-message'] || `Opt in for future communication from Jack's Axes! `}</span><Input id='communication' onClick={() => setCCheck(!cCheck)} name='communication' type='checkbox' checked={cCheck}></Input>
                </label>
              </Col>
            </Row>
            <hr />
            <Row>
              <p class="info">{data['waiver-info-2']}</p>
              <Row>
                <Col lg='4' sm='12'>
                  <FormGroup floating>
                    <Input
                      id="fname"
                      name="first-name"
                      placeholder="First Name"
                      type="text"
                    />
                    <Label for="fname">
                      Full Name
                    </Label>
                  </FormGroup>

                </Col>
                <Col lg='4' sm='12'>
                  <FormGroup floating>
                    <Label for="bday">
                      {bdaySelected ? '' : 'Birthday'}
                    </Label>
                    <Input
                      id="bday"
                      name="bday"
                      placeholder="Birthday"
                      onBlur={(e) => {
                        setSDis(false)
                        const birthDate = new Date(e.target.value);
                        const today = new Date();

                        let age = today.getFullYear() - birthDate.getFullYear();
                        const monthDiff = today.getMonth() - birthDate.getMonth();

                        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                          age--;
                        }

                        if (age < 2 && !nagging) {
                          toast.error(`that birthday doesn't look correct. Check the year.`)
                          setNagging(true)
                          setTimeout(() => {
                            setNagging(false)
                          }, 5000)
                        }

                        selectBday(true);
                        if (age < 18) {
                          setMinor(true)
                        } else {
                          setMinor(false)
                        }
                        setCalculatedAge(age); // Set the calculated age
                      }}
                      type="date"
                    />
                  </FormGroup>

                </Col>
                <Col lg='4' sm='12'>
                  <FormGroup floating>
                    <Input
                      id="age"
                      name="age"
                      placeholder="Age"
                      value={calculatedAge}
                      disabled
                      type="text"
                    />
                    <Label for="age">
                      Age
                    </Label>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col lg='4' sm='12'>
                  <FormGroup floating>
                    <Input
                      id="phone"
                      name="phone"
                      placeholder="Phone Number"
                      type="text"
                    />
                    <Label for="phone">
                      Phone Number
                    </Label>
                  </FormGroup>


                </Col>
                <Col lg='4' sm='12'>
                  <FormGroup floating>
                    <Input
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      type="email"
                    />
                    <Label for="email">
                      Email Address
                    </Label>
                  </FormGroup>

                </Col>
                <Col lg='4' sm='12'>
                  <FormGroup floating>
                    <Input
                      id="date"
                      name="date"
                      placeholder="Date"
                      type="date"
                    />
                    <Label for="date">
                      Date
                    </Label>
                  </FormGroup>
                </Col>
                <Col lg='4' sm='12'>
                  <div onClick={() => { setShowSig(false) }}>
                    <FormGroup floating>
                      <SignatureCanvas name='sig' penColor='green' ref={(ref) => {
                        setSig(ref)
                        window.sig = sig
                      }}
                        canvasProps={{ width: document.getElementById('email')?.offsetWidth > 300 ? document.getElementById('email')?.offsetWidth : 300, height: (document.getElementById('email')?.offsetHeight * 2), className: 'sigCanvas' }} />
                      <Label for="sig">
                        {showSig && "Signature"}
                      </Label>
                      <div onClick={sig.clear}>Clear Signature</div>
                      <p>{data['']}</p>
                    </FormGroup>
                  </div>
                </Col>

              </Row>

              {minor && (
                <>
                  <hr />
                  <div dangerouslySetInnerHTML={{ __html: data['waiver-minor-main'] }} />
                  <Row>
                    <Col md='1' />
                    <Col sm='12' md='10'>
                      <label for='gunderstand'>
                        <span>{data['waiver-minor-understand']}</span>
                      </label>
                      <Input id='gunderstand' name='gunderstand' onClick={() => setGCheck(!gCheck)} type='checkbox' checked={gCheck} ></Input>
                      {' *'}
                    </Col>
                  </Row>
                  <br /><br />
                  <hr />
                  <Row>
                    <p class="info">{data['waiver-minor-info-2']}</p>
                    <Col md='3' />
                    <Col md='6' sm='12'>
                      <Col lg='4' sm='12'>
                        <div onClick={() => { setShowGSig(false) }}>
                          <FormGroup floating>
                            <SignatureCanvas name='gSig' penColor='green' ref={(ref) => {
                              setGSig(ref)
                            }}
                              canvasProps={{ width: document.getElementById('email')?.offsetWidth > 350 ? document.getElementById('email')?.offsetWidth : 350, height: (document.getElementById('email')?.offsetHeight * 2), className: 'sigCanvas' }} />
                            <Label for="sig">
                              {showGSig && "Signature"}
                            </Label>
                            <div onClick={gSig.clear}>Clear Signature</div>
                            <p>{data['']}</p>
                          </FormGroup>
                        </div>
                      </Col>
                    </Col>
                    <p class="info">{data['waiver-minor-info-3']}</p>
                  </Row>

                </>
              )}
            </Row>
            <Button disabled={sDis}>
              {data['waiver-submit-button']}
            </Button>
          </Form>
          <div dangerouslySetInnerHTML={{__html: data['waiver-page-bottom']}} />
        </Col>
      </Row>
    </div>
  )
}

export default Waiver