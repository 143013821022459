import '../App.css';
import { useState, useEffect } from "react";



const CP = ({data}) => {
const [content, setContent] = useState('');

  useEffect(() => {
    const path = window.location.pathname.substr(1)
    const pageContent = data[`custompage-${path}`] || `<center><h1>404, not found</h1></center>`
    setContent(pageContent)
  }, [data])

  return (
  <div className="App" style={{backgroundColor: "white"}}>   
    <div style={{backgroundColor: 'white'}} dangerouslySetInnerHTML={{__html: content}} />

</div>
)
}

export default CP